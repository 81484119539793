{
  "name": "numara",
  "productName": "Numara",
  "description": "Numara Calculator",
  "version": "5.2.3",
  "author": {
    "name": "Timur Atalay",
    "email": "mail@numara.io",
    "url": "https://numara.io"
  },
  "license": "MIT",
  "homepage": "https://github.com/bornova/numara-calculator",
  "main": "src/main.js",
  "type": "module",
  "scripts": {
    "prebuild": "npm run prettier && npm run lint",
    "build": "node build.js",
    "dist": "npm run build && electron-builder",
    "lint": "eslint .",
    "lint:fix": "eslint --fix .",
    "prettier": "prettier --check .",
    "prettier:fix": "prettier --write .",
    "publish": "npm run build && electron-builder --x64 --arm64 -p always",
    "test": "npm run build && electron .",
    "wiki": "node src/misc/wiki.js"
  },
  "devDependencies": {
    "@eslint/js": "^9.9.1",
    "@formulajs/formulajs": "^4.4.6",
    "codemirror": "^5.65.17",
    "deep-diff": "^1.0.2",
    "electron": "^32.0.1",
    "electron-builder": "^24.13.3",
    "esbuild": "^0.23.1",
    "esbuild-plugin-polyfill-node": "^0.3.0",
    "eslint": "^9.9.1",
    "fs-extra": "^11.2.0",
    "function-plot": "^1.25.0",
    "lucide": "^0.433.0",
    "luxon": "^3.5.0",
    "mathjs": "^13.1.0",
    "mousetrap": "^1.6.5",
    "mousetrap-global-bind": "^1.1.0",
    "prettier": "^3.3.3",
    "uikit": "^3.21.11"
  },
  "dependencies": {
    "electron-log": "^5.1.7",
    "electron-store": "^10.0.0",
    "electron-updater": "^6.3.4"
  }
}
